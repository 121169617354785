/* @v3.4 - prueba speech - funcional webapp {pendiente posibles browsers supported} */
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState, useEffect } from 'react';
import classNames from 'classnames';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload,
  faMicrophone,
  faMicrophoneSlash
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import mammoth from 'mammoth';
import { parseISO, format } from 'date-fns';
import * as pdfjsLib from 'pdfjs-dist';

// Speech Recognition Types
interface SpeechRecognitionEvent extends Event {
  results: SpeechRecognitionResultList;
  resultIndex: number;
  error: any;
}

interface SpeechRecognitionResultList {
  length: number;
  item(index: number): SpeechRecognitionResult;
  [index: number]: SpeechRecognitionResult;
}

interface SpeechRecognitionResult {
  isFinal: boolean;
  length: number;
  item(index: number): SpeechRecognitionAlternative;
  [index: number]: SpeechRecognitionAlternative;
}

interface SpeechRecognitionAlternative {
  transcript: string;
  confidence: number;
}

interface SpeechRecognition extends EventTarget {
  continuous: boolean;
  interimResults: boolean;
  lang: string;
  onerror: (event: SpeechRecognitionEvent) => void;
  onend: () => void;
  onresult: (event: SpeechRecognitionEvent) => void;
  start(): void;
  stop(): void;
  abort(): void;
}

type SpeechRecognitionConstructor = {
  new (): SpeechRecognition;
  prototype: SpeechRecognition;
};

declare global {
  interface Window {
    SpeechRecognition: SpeechRecognitionConstructor;
    webkitSpeechRecognition: SpeechRecognitionConstructor;
  }
}

interface FileProcessingResult {
  content: string;
  metadata?: {
    author?: string;
    createdAt?: string;
    modifiedAt?: string;
    pageCount?: number;
    [key: string]: any;
  };
}

const LoadingText = ({ text = "Processing" }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => {
        if (prev.length >= 3) return '';
        return prev + '.';
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-muted fs-8">
      {text}{dots}
    </div>
  );
};

const TypingIndicator = () => (
  <>
    {[0, 1, 2].map((i) => (
      <div
        key={i}
        className=""
        style={{ animationDelay: `${i * 0.2}s` }}
      />
    ))}
    <Spinner animation="border" size="sm" />
  </>
);

const ChatWidgetFooter = () => {
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<FileProcessingResult | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState<SpeechRecognition | null>(null);
  const [speechError, setSpeechError] = useState<string | null>(null);
  
  const { sentMessage, isProcessingInput } = useChatWidgetContext();

  // Speech Recognition Setup
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (SpeechRecognition) {
        const recognition = new SpeechRecognition();
        recognition.continuous = false;
        recognition.interimResults = false;
        recognition.lang = 'es-ES';

        recognition.onresult = (event: SpeechRecognitionEvent) => {
          const transcript = event.results[0][0].transcript.trim();
          setMessageText(prevText => {
            const separator = prevText.endsWith(' ') || prevText === '' ? '' : ' ';
            return prevText + separator + transcript;
          });
        };

        recognition.onerror = (event: SpeechRecognitionEvent) => {
          console.error('Speech recognition error', event.error);
          setSpeechError(event.error);
          setIsListening(false);
        };

        recognition.onend = () => {
          if (isListening) {
            recognition.start();
          }
          setIsListening(false);
        };

        recognition.addEventListener('start', () => {
          setIsListening(true);
          setSpeechError(null);
        });

        setRecognition(recognition);
      }
    }
  }, []);

  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 
      `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  }, []);

  useEffect(() => {
    if (!isProcessingInput && isProcessingFile) {
      setFileAttachment(null);
      setFileContent(null);
      setIsProcessingFile(false);
    }
  }, [isProcessingInput]);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }

      return {
        content: fullText.trim(),
        metadata: {
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        resolve(result);
      };
      reader.onerror = error => reject(error);
    });
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      let fullText = '';
  
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
  
      return {
        content: fullText.trim(),
        metadata: {
          sheets: workbook.SheetNames.length,
          type: 'Excel Document'
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
  
    try {
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
        case 'json':
          return await processTextFile(file);
        default:
          throw new Error(`Unsupported file type: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    }
  };

  const convertImageToAttachment = (image: File) => ({
    name: image.name, // Nombre del archivo
    size: (image.size / 1024).toFixed(2) + ' KB', // Tamaño formateado en KB
    format: image.type.split('/')[1] || 'img', // Formato (png, jpg, etc.)
    preview: URL.createObjectURL(image), // URL para mostrar como preview
    file: image // Agrega el archivo original en caso de que sea necesario
  });

  const handleFileImage = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      const fileType = file.name.split('.').pop()?.toLowerCase();
  
      const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'svg'];
      if (imageExtensions.includes(fileType || '')) {
        setImageAttachments((prevImages) => [...prevImages, file]);
        return;
      }
  
      setFileAttachment(file);
      setIsProcessingFile(true);
  
      try {
        const result = await processFile(file);
        if (result) {
          setFileContent(result);
        }
      } catch (error) {
        console.error('Error processing image:', error);
      } finally {
        setIsProcessingFile(false);
      }
    }
  };

  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      const fileType = file.name.split('.').pop()?.toLowerCase();
  
      const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'svg'];
      if (imageExtensions.includes(fileType || '')) {
        setImageAttachments((prevImages) => [...prevImages, file]);
        return;
      }
  
      setFileAttachment(file);
      setIsProcessingFile(true);
  
      try {
        const result = await processFile(file);
        if (result) {
          setFileContent(result);
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsProcessingFile(false);
      }
    }
  };

  const toggleListening = () => {
    if (!recognition) {
      setSpeechError('Speech recognition is not supported in this browser');
      return;
    }
    
    if (isListening) {
      recognition.stop();
      setIsListening(false);
    } else {
      setSpeechError(null);
      recognition.start();
      setIsListening(true);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (messageText || (fileAttachment && fileContent) || imageAttachments.length > 0) {
      let finalMessage = messageText;
  
      if (fileAttachment && fileContent) {
        const metadata = Object.entries(fileContent.metadata || {})
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n');
        finalMessage = `${messageText}\n\n<documents>\n<document index="1">\n<source>${fileAttachment.name}</source>\n<metadata>\n${metadata}\n</metadata>\n<document_content>\n${fileContent.content}\n</document_content>\n</documents>`;
        setIsProcessingFile(true);
      }
  
      let base64Images: string[] = [];
      if (imageAttachments.length > 0) {
        try {
          base64Images = await Promise.all(
            imageAttachments.map(image => convertFileToBase64(image))
          );
        } catch (error) {
          console.error('Error converting images to Base64:', error);
        }
      }
  
      sentMessage({
        message: finalMessage,
        attachments: {
          images: base64Images,
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment, finalMessage)
            : undefined
        }
      });
  
      setMessageText('');
      setImageAttachments([]);
      setFileAttachment(null);
      setFileContent(null);
      setIsProcessingFile(false);
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      {fileAttachment && (
        <div className="mb-2">
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)} // Procesa los archivos existentes como FileAttachment
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent(null);
              setIsProcessingFile(false);
            }}
          />
          {isProcessingFile && <LoadingText />}
        </div>
      )}

      {imageAttachments.map((image, index) => (
        <div key={index} className="mb-2">
          <AttachmentPreview
            attachment={convertImageToAttachment(image)}
            size="xl"
            handleRemove={() => {
              setImageAttachments((prevImages) =>
                prevImages.filter((_, i) => i !== index)
              );
            }}
          />
        </div>
      ))}

      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-4">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder={`Ask ${process.env.REACT_APP_NAME}...`}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
              }
            }}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput || isProcessingFile}
          />
        </div>

        {isProcessingInput && <TypingIndicator />}
        
        <Button
          className={`p-2 ${isListening ? 'text-danger' : 'text-muted'}`}
          type="button"
          onClick={toggleListening}
          disabled={isProcessingInput || isProcessingFile}
        >
          <FontAwesomeIcon 
            icon={isListening ? faMicrophone : faMicrophoneSlash} 
            className="fs-9"
            transform="down-1"
          />
        </Button>
        <div>
        <Button className="p-0" disabled={isProcessingFile}>
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="ImagesAttachment"
            >
              <FontAwesomeIcon icon={faImage} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="ImagesAttachment"
            accept=".png,.jpg,.gif,.jpeg,.svg"
            onChange={handleFileImage}
          />
        </div>
        <div>
          <Button className="p-0" disabled={isProcessingFile}>
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="widgetAttachments"
            >
              <FontAwesomeIcon icon={faPaperclip} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="widgetAttachments"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv,.json"
            onChange={handleFileChange}
          />
        </div>

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput || isProcessingFile}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="fs-9" />
        </Button>
      </div>

      {speechError && (
        <div className="text-danger mt-1 small">
          {speechError}
        </div>
      )}
    </form>
  );
};

export default ChatWidgetFooter;

/* @v3.3 - chequear analisis del documento que se adjunta pero no lee su contenido 
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState, useEffect } from 'react';
import classNames from 'classnames';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import mammoth from 'mammoth';
import { parseISO, format } from 'date-fns';
import * as pdfjsLib from 'pdfjs-dist';

const LoadingText = ({ text = "Processing" }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => {
        if (prev.length >= 3) return '';
        return prev + '.';
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-muted fs-8">
      {text}{dots}
    </div>
  );
};

interface FileProcessingResult {
  content: string;
  metadata?: {
    author?: string;
    createdAt?: string;
    modifiedAt?: string;
    pageCount?: number;
    [key: string]: any;
  };
}

const ChatWidgetFooter = () => {
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<FileProcessingResult | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const { sentMessage, isProcessingInput } = useChatWidgetContext();

  const TypingIndicator = () => (
    <>
      {[0, 1, 2].map((i) => (
        <div
          key={i}
          className=""
          style={{ animationDelay: `${i * 0.2}s` }}
        />
      ))}
        <Spinner animation="border" size="sm" />
    </>
  );

  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 
      `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  }, []);

  useEffect(() => {
    if (!isProcessingInput && isProcessingFile) {
      setFileAttachment(null);
      setFileContent(null);
      setIsProcessingFile(false);
    }
  }, [isProcessingInput]);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }

      return {
        content: fullText.trim(),
        metadata: {
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      let fullText = '';
  
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
  
      return {
        content: fullText.trim(),
        metadata: {
          sheets: workbook.SheetNames.length,
          type: 'Excel Document'
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    
    try {
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
        case 'json':
          return await processTextFile(file);
        default:
          throw new Error(`Unsupported file type: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    }
  };

  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      setFileAttachment(file);
      setIsProcessingFile(true);
      
      try {
        const result = await processFile(file);
        if (result) {
          setFileContent(result);
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsProcessingFile(false);
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (messageText || (fileAttachment && fileContent)) {
      let finalMessage = messageText;

      if (fileAttachment && fileContent) {
        //finalMessage = `${messageText}\n\nArchivo adjunto: ${fileAttachment.name}`;
        /* const metadata en chatbox -->
        const metadata = Object.entries(fileContent.metadata || {})
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');
        finalMessage = `${messageText}\n\n<documents>\n<document index="1">\n<source>${fileAttachment.name}</source>\n<metadata>\n${metadata}\n</metadata>\n<document_content>\n${fileContent.content}\n</document_content>\n</document>\n</documents>`;
        /* end metadata en chatbox -->
        setIsProcessingFile(true);
      }

      sentMessage({
        message: messageText, // changed this line
        attachments: {
          images: imageAttachments.map(imageAttachment =>
            URL.createObjectURL(imageAttachment)
          ),
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment, finalMessage)
            : undefined
        }
      });

      setMessageText('');
      setImageAttachments([]);
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      {fileAttachment && (
        <div className={classNames({ 'mb-2': fileAttachment })}>
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent(null);
              setIsProcessingFile(false);
            }}
          />
          {isProcessingFile && <LoadingText />}
        </div>
      )}

      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-4">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder={`Ask ${process.env.REACT_APP_NAME}...`}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
              }
            }}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput || isProcessingFile}
          />
        </div>
        {isProcessingInput && <TypingIndicator />}
        <div>
          <Button className="p-0" disabled={isProcessingFile}>
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="widgetAttachments"
            >
              <FontAwesomeIcon icon={faPaperclip} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="widgetAttachments"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv,.json"
            onChange={handleFileChange}
          />
        </div>

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput || isProcessingFile}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="fs-9" />
        </Button>
      </div>
    </form>
  );
};

export default ChatWidgetFooter;

/* @v3.2 - agrego spinner del bot pensando mientras responde 
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState, useEffect } from 'react';
import classNames from 'classnames';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import mammoth from 'mammoth';
import { parseISO, format } from 'date-fns';
import * as pdfjsLib from 'pdfjs-dist';

interface FileProcessingResult {
  content: string;
  metadata?: {
    author?: string;
    createdAt?: string;
    modifiedAt?: string;
    pageCount?: number;
    [key: string]: any;
  };
}

const ChatWidgetFooter = () => {
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<FileProcessingResult | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const { sentMessage, isProcessingInput } = useChatWidgetContext();

  const TypingIndicator = () => (
    <>
      {[0, 1, 2].map((i) => (
        <div
          key={i}
          className=""
          style={{ animationDelay: `${i * 0.2}s` }}
        />
      ))}
        <Spinner animation="border" size="sm" />
    </>
  );

  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 
      `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  }, []);

  useEffect(() => {
    if (!isProcessingInput && isProcessingFile) {
      setFileAttachment(null);
      setFileContent(null);
      setIsProcessingFile(false);
    }
  }, [isProcessingInput]);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }

      return {
        content: fullText.trim(),
        metadata: {
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      let fullText = '';
  
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
  
      return {
        content: fullText.trim(),
        metadata: {
          sheets: workbook.SheetNames.length,
          type: 'Excel Document'
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    
    try {
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
        case 'json':
          return await processTextFile(file);
        default:
          throw new Error(`Unsupported file type: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    }
  };

  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      setFileAttachment(file);
      setIsProcessingFile(true);
      
      try {
        const result = await processFile(file);
        if (result) {
          setFileContent(result);
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsProcessingFile(false);
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (messageText || (fileAttachment && fileContent)) {
      let finalMessage = messageText;

      if (fileAttachment && fileContent) {
        // archivo adjunto sin preview de contenido
        finalMessage = `${messageText}\n\nArchivo adjunto: ${fileAttachment.name}`;
        setIsProcessingFile(true);
      }

      sentMessage({
        message: finalMessage,
        attachments: {
          images: imageAttachments.map(imageAttachment =>
            URL.createObjectURL(imageAttachment)
          ),
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment)
            : undefined
        }
      });

      setMessageText('');
      setImageAttachments([]);
      // No limpiamos fileAttachment ni fileContent aquí para mantener el estado de "procesando"
      // Se limpiarán cuando recibamos la respuesta
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      {fileAttachment && (
        <div className={classNames({ 'mb-2': fileAttachment })}>
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent(null);
              setIsProcessingFile(false);
            }}
          />
          {isProcessingFile && (
            <div className="text-muted fs-8">Processing...</div>
          )}
        </div>
      )}

      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-4">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder="Ask Hazbot..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
              }
            }}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput || isProcessingFile}
          />
        </div>
        {isProcessingInput && <TypingIndicator />}
        <div>
          <Button className="p-0" disabled={isProcessingFile}>
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="widgetAttachments"
            >
              <FontAwesomeIcon icon={faPaperclip} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="widgetAttachments"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv,.json"
            onChange={handleFileChange}
          />
        </div>

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput || isProcessingFile}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="fs-9" />
        </Button>
      </div>
    </form>
  );
};

export default ChatWidgetFooter; */

/* @v3.2 - agrego spinner del bot pensando mientras responde 
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState, useEffect } from 'react';
//import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
import classNames from 'classnames';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import mammoth from 'mammoth';
import { parseISO, format } from 'date-fns';
import * as pdfjsLib from 'pdfjs-dist';

interface FileProcessingResult {
  content: string;
  metadata?: {
    author?: string;
    createdAt?: string;
    modifiedAt?: string;
    pageCount?: number;
    [key: string]: any;
  };
}

const ChatWidgetFooter = () => {
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<FileProcessingResult | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const { sentMessage, isProcessingInput } = useChatWidgetContext();

  const TypingIndicator = () => (
    <>
      {[0, 1, 2].map((i) => (
        <div
          key={i}
          className=""
          style={{ animationDelay: `${i * 0.2}s` }}
        />
      ))}
        <Spinner animation="border" size="sm" />
    </>
  );

  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 
      `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  }, []);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }

      return {
        content: fullText.trim(),
        metadata: {
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      let fullText = '';
  
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
  
      return {
        content: fullText.trim(),
        metadata: {
          sheets: workbook.SheetNames.length,
          type: 'Excel Document'
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    
    try {
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
        case 'json':
          return await processTextFile(file);
        default:
          throw new Error(`Unsupported file type: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    }
  };

  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      setFileAttachment(file);
      setIsProcessingFile(true);
      
      try {
        const result = await processFile(file);
        if (result) {
          setFileContent(result);
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsProcessingFile(false);
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (messageText || (fileAttachment && fileContent)) {
      let finalMessage = messageText;

      if (fileAttachment && fileContent) {
        const metadata = Object.entries(fileContent.metadata || {})
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n');

        finalMessage = `${messageText}\n\n<documents>\n<document index="1">\n<source>${fileAttachment.name}</source>\n<metadata>\n${metadata}\n</metadata>\n<document_content>\n${fileContent.content}\n</document_content>\n</document>\n</documents>`;
      }

      sentMessage({
        message: finalMessage,
        attachments: {
          images: imageAttachments.map(imageAttachment =>
            URL.createObjectURL(imageAttachment)
          ),
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment)
            : undefined
        }
      });

      setMessageText('');
      setImageAttachments([]);
      setFileAttachment(null);
      setFileContent(null);
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      {fileAttachment && (
        <div className={classNames({ 'mb-2': fileAttachment })}>
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent(null);
            }}
          />
          {isProcessingFile && (
            <div className="text-muted fs-8">Processing file...</div>
          )}
        </div>
      )}

      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-4">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder="Ask Hazbot..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
              }
            }}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput || isProcessingFile}
          />
        </div>
        {isProcessingInput && <TypingIndicator />}
        <div>
          <Button className="p-0" disabled={isProcessingFile}>
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="widgetAttachments"
            >
              <FontAwesomeIcon icon={faPaperclip} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="widgetAttachments"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv,.json"
            onChange={handleFileChange}
          />
        </div>

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput || isProcessingFile}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="fs-9" />
        </Button>
      </div>
    </form>
  );
};

export default ChatWidgetFooter;

/* @v3.1 - agrego formatos excel pdf powerpoint word y esas mariconadas */
/*
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState, useEffect } from 'react';
//import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
import classNames from 'classnames';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import mammoth from 'mammoth';
import { parseISO, format } from 'date-fns';
import * as pdfjsLib from 'pdfjs-dist';

interface FileProcessingResult {
  content: string;
  metadata?: {
    author?: string;
    createdAt?: string;
    modifiedAt?: string;
    pageCount?: number;
    [key: string]: any;
  };
}

const ChatWidgetFooter = () => {
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<FileProcessingResult | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const { sentMessage, isProcessingInput } = useChatWidgetContext();
  const TypingIndicator = () => (
    <div className="typing-indicator position-absolute bottom-100 start 0 mb-2 ms-4">
      <div className="bg-light rounded-pill px-3 py-2 d-inline-flex align-items-center">
        <div className="typing-dots">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
        <span className="ms-2 text-muted fs-8">Bot está escribiendo...</span>
      </div>
      <style>{`
        .typing-dots {
          display: flex;
          gap: 4px;
        }
        .dot {
          width: 6px;
          height: 6px;
          background: #6c757d;
          border-radius: 50%;
          animation: typing 1.4s infinite;
          opacity: 0.6;
        }
        .dot:nth-child(2) { animation-delay: 0.2s; }
        .dot:nth-child(3) { animation-delay: 0.4s; }
        @keyframes typing {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-4px); }
        }
      `}</style>
    </div>
  );

  useEffect(() => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 
      `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  }, []);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }

      return {
        content: fullText.trim(),
        metadata: {
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      let fullText = '';
  
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
  
      return {
        content: fullText.trim(),
        metadata: {
          sheets: workbook.SheetNames.length,
          type: 'Excel Document'
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    
    try {
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
        case 'json':
          return await processTextFile(file);
        default:
          throw new Error(`Unsupported file type: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    }
  };

  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      setFileAttachment(file);
      setIsProcessingFile(true);
      
      try {
        const result = await processFile(file);
        if (result) {
          setFileContent(result);
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsProcessingFile(false);
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (messageText || (fileAttachment && fileContent)) {
      let finalMessage = messageText;

      if (fileAttachment && fileContent) {
        const metadata = Object.entries(fileContent.metadata || {})
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n');

        finalMessage = `${messageText}\n\n<documents>\n<document index="1">\n<source>${fileAttachment.name}</source>\n<metadata>\n${metadata}\n</metadata>\n<document_content>\n${fileContent.content}\n</document_content>\n</document>\n</documents>`;
      }

      sentMessage({
        message: finalMessage,
        attachments: {
          images: imageAttachments.map(imageAttachment =>
            URL.createObjectURL(imageAttachment)
          ),
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment)
            : undefined
        }
      });

      setMessageText('');
      setImageAttachments([]);
      setFileAttachment(null);
      setFileContent(null);
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      {fileAttachment && (
        <div className={classNames({ 'mb-2': fileAttachment })}>
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent(null);
            }}
          />
          {isProcessingFile && (
            <div className="text-muted fs-8">Processing file...</div>
          )}
        </div>
      )}

      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-6">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder="Tell me something..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
              }
            }}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput || isProcessingFile}
          />
        </div>

        <div>
          <Button className="p-0" disabled={isProcessingFile}>
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="widgetAttachments"
            >
              <FontAwesomeIcon icon={faPaperclip} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="widgetAttachments"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv,.json"
            onChange={handleFileChange}
          />
        </div>

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput || isProcessingFile}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="fs-9" />
        </Button>
      </div>
    </form>
  );
};

export default ChatWidgetFooter; */

/* @v3.0 - Lectura archivos adjuntos en formato texto plano */ 

/*
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { ChangeEvent, FormEvent, KeyboardEvent, useState } from 'react';
import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
import classNames from 'classnames';
import { convertFileToAttachment } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {
  faImage,
  faPaperPlane,
  faPaperclip,
  faTrash,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Form, Dropdown } from 'react-bootstrap';

const ChatWidgetFooter = () => {
  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<string>('');
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);
  const { sentMessage, isProcessingInput } = useChatWidgetContext();

  const readFileContent = async (file: File) => {
    try {
      const content = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.readAsText(file);
      });
      
      setFileContent(content);
      return content;
    } catch (error) {
      console.error('Error reading file:', error);
      return null;
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (messageText || fileAttachment || imageAttachments.length > 0) {
      let finalMessage = messageText;

      // incluir contenido en el mensaje (opcional esta wea)
      if (fileAttachment && fileContent) {
        finalMessage = `${messageText}\n\n<documents>\n<document index="1">\n<source>${fileAttachment.name}</source>\n<document_content>\n${fileContent}\n</document_content>\n</document>\n</documents>`;
      }

      sentMessage({
        message: finalMessage,
        attachments: {
          images: imageAttachments.map(imageAttachment =>
            URL.createObjectURL(imageAttachment)
          ),
          file: fileAttachment
            ? convertFileToAttachment(fileAttachment)
            : undefined
        }
      });

      setMessageText('');
      setImageAttachments([]);
      setFileAttachment(null);
      setFileContent('');
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
    }
  };

  const handleFileChange = async ({
    target: { files }
  }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      const file = files[0];
      setFileAttachment(file);
      await readFileContent(file);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {fileAttachment && (
        <div className={classNames({ 'mb-2': fileAttachment })}>
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => {
              setFileAttachment(null);
              setFileContent('');
            }}
          />
        </div>
      )}

      {imageAttachments && (
        <div
          className={classNames('d-flex gap-2', {
            'mb-2': imageAttachments.length
          })}
        >
          {imageAttachments.map((attachment, index) => (
            <ImageAttachmentPreview
              key={index}
              image={URL.createObjectURL(attachment)}
              handleClose={() => {
                setImageAttachments(
                  imageAttachments.filter((_, i) => index !== i)
                );
              }}
            />
          ))}
        </div>
      )}
      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-6">
          <ReactTextareaAutosize
            className="chat-textarea form-control outline-none border-0 scrollbar resize-none"
            placeholder="Tell me something..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={handleKeyDown}
            minRows={1}
            maxRows={5}
            disabled={isProcessingInput}
          />
        </div>

        <div>
          <Button className="p-0">
            <label
              className="text-body-quaternary fs-9 cursor-pointer"
              htmlFor="widgetAttachments"
            >
              <FontAwesomeIcon icon={faPaperclip} transform="down-1" />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="widgetAttachments"
            accept=".csv,.txt,.json"
            onChange={handleFileChange}
          />
        </div>

        <Button
          className="p-0 border-0 send-btn"
          type="submit"
          disabled={isProcessingInput}
        >
          <FontAwesomeIcon icon={faPaperPlane} className="fs-9" />
        </Button>
      </div>
    </form>
  );
};

export default ChatWidgetFooter;
*/
