import { useState } from 'react';
import { Card, CardHeader, CardTitle } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import { ShellService } from '../service/shellService';
import { useUserContext } from './UserContext';

const ShellCommand = () => {
  const { user, isLoading } = useUserContext();

  if (isLoading || !user) {
    return (
      <Card className="w-100">
        <CardHeader>
          <CardTitle>Command console</CardTitle>
        </CardHeader>
        <div className="p-4">
          <div className="text-center">Loading...</div>
        </div>
      </Card>
    );
  }

  const allowedUsernames = ['doremysweet830a', 'demoanubis788', 'franciscoaliaga308'];
  if (!allowedUsernames.includes(user.username)) {
    return (
      <Card className="w-100">
        <CardHeader>
          <CardTitle>Command console</CardTitle>
        </CardHeader>
        <div className="p-4">
          <div className="text-center">You haven't perms to use command console.</div>
        </div>
      </Card>
    );
  }

  const [command, setCommand] = useState('');
  const [outputs, setOutputs] = useState<string[]>([]);
  const [isSending, setisSending] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sendCommand = async () => {
    try {
      setisSending(true);
      setError(null);

      const shellInput = { command };
      const shellOutput = await ShellService.sendCommand(shellInput);

      setOutputs((prevOutputs) => [
        ...prevOutputs,
        `AI@${process.env.REACT_APP_NAME}:~$ ${command}`,
        shellOutput.response,
      ]);
      setCommand(''); 
    } catch (err: any) {
      setError(err instanceof Error ? err.message : 'Error desconocido');
    } finally {
      setisSending(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (command && !isSending) {
        sendCommand();
      }
    }
  };

  return (
    <Card className="w-100">
      <CardHeader>
        <CardTitle>Command console</CardTitle>
      </CardHeader>
      <div className="p-4">
        <div className="d-flex gap-2 mb-3">
          <textarea
            className="form-control"
            rows={2}
            placeholder="Escribe tu comando aquí..."
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            onKeyDown={handleKeyPress}
            disabled={isSending}
          />

          <Button
            variant="primary"
            onClick={sendCommand}
            disabled={!command || isSending}
            className="d-block"
          >
            {isSending ? 'Enviando...' : 'Enviar'}
          </Button>

          <Button
            variant="outline-secondary"
            onClick={() => {
              setCommand('');
              setOutputs([]);
              setError(null);
            }}
            disabled={isSending}
          >
            Limpiar
          </Button>
        </div>

        {error && (
          <div className="mb-3 text-danger">
            Error: {error}
          </div>
        )}

        <div
          className="p-3 bg-dark text-light rounded"
          style={{
            minHeight: '200px',
            maxHeight: '400px',
            overflowY: 'auto',
            fontFamily: 'monospace',
          }}
        >
          {outputs.length > 0 ? (
            outputs.map((output, index) => (
              <div key={index}>{output} </div>
            ))
          ) : (
            <span>Command output:</span>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ShellCommand;