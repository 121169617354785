import axios from 'axios';
import { apiUrl } from '../helpers/utils';
import { ILogin, IMailUpdateVerification, IPasswordUpdate, IRegistration, IUser } from 'data/models/user/IUser';
import { FileAttachment } from 'components/common/AttachmentPreview';

const getHatateToken = () => {
    const token = localStorage.getItem('hatateToken');
    return token ? `Bearer ${token}` : '';
  };

export const UserService = {
  register: async (registration: IRegistration): Promise<string> => {
    const { confirmPassword, ...registrationData } = registration;
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.post(`${apiUrl}authentication/register`, registrationData, { headers });

      localStorage.setItem("hatateToken", response.data.token);
      
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data.message : error.message;
    }
  },

  login: async (login: ILogin): Promise<string> => {
    const jsonParams = JSON.stringify(login);

    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await axios.post(`${apiUrl}authentication/login`, jsonParams, { headers });

      localStorage.setItem("hatateToken", response.data.token);

      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  logout: async () => {
    localStorage.removeItem('hatateToken');
  },

  getUser: async (id?: string) : Promise<IUser> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    const url = id ? `${apiUrl}/user/${id}` : `${apiUrl}user/profile/me`;
    try {
      const response = await axios.get(url, { headers });

      return response.data;
    } catch (error: any) {
      throw error;
    }
  },

  getChatTokensRemaining: async (id?: string) : Promise<Number> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    const url = id ? `${apiUrl}/user/${id}` : `${apiUrl}user/profile/me`;
    try {
      const response = await axios.get(url, { headers });

      const userData : IUser = response.data;

      const tokenUsed = userData.botInfo.tokenUsed;
      const tokenLimit = userData.botInfo.tokenLimit;

      return tokenLimit - tokenUsed;
    } catch (error: any) {
      throw error;
    }
  },

  getChatTokensUsed: async (id?: string) : Promise<Number> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    const url = id ? `${apiUrl}/user/${id}` : `${apiUrl}user/profile/me`;
    try {
      const response = await axios.get(url, { headers });

      const userData : IUser = response.data;

      const tokenUsed = userData.botInfo.tokenUsed; // Tokens usados
      
      return tokenUsed;
    } catch (error: any) {
      throw error;
    }
  },

  getChatTokensLimit: async (id?: string) : Promise<Number> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    const url = id ? `${apiUrl}/user/${id}` : `${apiUrl}user/profile/me`;
    try {
      const response = await axios.get(url, { headers });

      const userData : IUser = response.data;

      const tokenUsed = userData.botInfo.tokenLimit; // limit token
      
      return tokenUsed;
    } catch (error: any) {
      throw error;
    }
  },

  getAvailableNeuroSkills: async () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    const url = `${apiUrl}/bot/neuroskill/list`;
    try {
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  getPromptResponse: async (
    chatId: string,
    neuroSkill: string,
    neuroModule: string,
    messages: { role: 'user' | 'assistant'; content: string; time: string; }[],
    fileAttachment?: FileAttachment
  ): Promise<string> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
  
    const url = apiUrl + "bot/prompt";
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          chatId: chatId,
          skill: neuroSkill,
          module: neuroModule,
          prompt: messages,
          raw: fileAttachment?.raw,
        }),
      });
  
      if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(`Error ${response.status}: ${errorMessage.message}`);
      }
  
      const data = await response.json();
      if (typeof data.reply !== 'string') {
        throw new Error('Unexpected response format: reply is not a string');
      }
      
      return data.reply;
    } catch (error: any) {
      return error.message;
    }
  },

  getStreamResponse: async (
    chatId: string,
    neuroSkill: string,
    neuroModule: string,
    messages: { role: 'user' | 'assistant'; content: string; time: string, attachments?: { images?: string[]; file?: FileAttachment } }[],
    onMessage: (message: string) => void,
    onError?: (error: string) => void,
    onComplete?: () => void,
    imageAttachment?: string,
  ): Promise<void> => {
    const url = apiUrl + 'bot/stream';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          chatId: chatId,
          skill: neuroSkill,
          module: neuroModule,
          prompt: messages,
          raw: imageAttachment
        }),
      });
  
      if (!response.body) {
        if (onError) {
          onError('No se pudo obtener el cuerpo del stream.');
        }
        return;
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
  
      let buffer = '';
      let isDone = false;
  
      while (!isDone) {
        const { value, done } = await reader.read();
        isDone = done;
  
        if (value) {
          buffer += decoder.decode(value, { stream: true });
          
          const lines = buffer.split('\n');
          
          buffer = lines.pop() ?? '';
  
          for (const line of lines) {
            if (line.startsWith('data:')) {
              const cleanLine = line.slice(5).trim();
  
              try {
                const data = JSON.parse(cleanLine);
  
                if (data.done) {
                  if (onComplete) {
                    onComplete();
                  }
                  return;
                } else if (data.error) {
                  if (onError) {
                    onError(data.error);
                  }
                  return;
                } else if (data.content) {
                  if (onMessage) {
                    onMessage(data.content);
                  }
                }
              } catch (parseError) {
                console.error('Error al parsear JSON del stream:', cleanLine, parseError);
              }
            }
          }
        }
      }
  
      if (onComplete) {
        onComplete();
      }
    } catch (error: any) {
      if (onError) {
        onError(`Error en la conexión de streaming: ${error.message}`);
      }
    }
  },

  getChatSessionById: async (chatId: string) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    const url = `${apiUrl}/bot/chatsession/${chatId}`;
    try {
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error: any) {
      console.error('Error in getChatSessionById:', error);
      throw error.response ? error.response.data : error.message;
    }
  },

  getChatSessionsPaginated: async (limit: number = 10, page: number = 1) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };

    const url = `${apiUrl}/bot/chatsessions?limit=${limit}&page=${page}`;
    try {
      const response = await axios.get(url, { headers });
      return response.data; 
    } catch (error: any) {
      console.error('Error in getChatSessionsPaginated:', error);
      throw error.response ? error.response.data : error.message;
    }
  },

  mailVerification: async () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    try {
      const response = await axios.get(`${apiUrl}user/update-mail-verification`, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  mailUpdate: async (update: IMailUpdateVerification) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    try {
      const response = await axios.post(`${apiUrl}user/update-mail-verification`, update, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  updateUser: async (user: IUser) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    try {
      const response = await axios.put(`${apiUrl}user`, user, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  passwordUpdate: async (request: IPasswordUpdate) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    try {
      const response = await axios.put(`${apiUrl}user/update-password`, request, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  userListAutocompleteObservable: async (own = false, search = '') => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    const route = own ? '/true' : '';
    const searchQuery = search ? `?search=${search}` : '';
    try {
      const response = await axios.get(`${apiUrl}user/actions/list-all${route}${searchQuery}`, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  userIp: async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data;
    } catch (error: any) {
      console.log(error)
      throw error.response ? error.response.data : error.message;
    }
  }
};