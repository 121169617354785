// Checkout.tsx
import Button from 'components/base/Button';
import Section from 'components/base/Section';
import { Card, Col, Form, Row } from 'react-bootstrap';
import EcomAddressTable from 'components/tables/EcomAddressTable';
import { shippingDetailsAddress } from 'data/e-commerce';
import DeliveryType from 'components/modules/e-commerce/checkout/DeliveryType';
import { PaymentMethod } from 'components/modules/e-commerce/checkout/PaymentMethod';
import { currencyFormat } from 'helpers/utils';
import CheckoutSummaryCard from 'components/modules/e-commerce/checkout/CheckoutSummaryCard';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { defaultBreadcrumbItems } from 'data/commonData';
import { useLocation, Navigate } from 'react-router-dom';
import React from 'react';
import { useCart } from 'components/CartContext';
import DefaultCartNavbar from 'components/navbars/default-landing-navbar/DefaultCartNavbar';
import bgbiz from 'assets/img/bg/bg-23.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import CheckoutForm from 'components/modules/auth/SignUpFormCart';
import AddressSection from 'components/modules/landing/alternate/AddressSection';
import Footer from 'components/modules/landing/alternate/Footer';
import { useUserContext } from 'layouts/UserContext';

const Checkout = () => {
  const { user, isAuthenticated } = useUserContext();

  const { totalPrice, discount } = useCart();
  const location = useLocation();
  const { finalTotal = 0, originalTotal = 0 } = location.state || {};

  // Si no hay total o es 0, redirigir al carrito
  if (!finalTotal || finalTotal <= 0) {
    return <Navigate to="/cart" replace />;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Aquí puedes agregar la lógica de pago
    console.log('Procesando pago por:', finalTotal);
  };

  return (
    <>
    <DefaultCartNavbar />
      <div className="bg-body-emphasis dark__bg-gray-1200" style={{
        backgroundImage: `url(${bgbiz})`,
        backgroundSize: 'contain', // Change to 'contain' to fit within the section
        backgroundPosition: 'center',
        zIndex: 0,
        height: '100%',
        width: '100%',
        backgroundRepeat: 'no-repeat'
      }}>
        <Section small className="py-1">
          <h2 className="mb-6 mt-1text-center text-gradient-info mt-2 mb-4">Check out <FontAwesomeIcon icon={faShoppingCart} size="lg" className="text-warning text-xl" /></h2>
          <Row className="justify-content-between">
            <Col lg={7}>
              <form onSubmit={handleSubmit}>
                <Card>
                <div className="pt-1 m-2">
                {!user ? (
                  <h4 className="mb-0 me-0">Register new account</h4>) : (
                    <h4 className="mb-0 me-0">Continue to pay</h4>
                  )}
                  {/*<Button variant="link" className="p-0" type="button">
                    Edit
                  </Button>*/}
                  <CheckoutForm layout="split" />
                </div>
                {/*<EcomAddressTable data={shippingDetailsAddress} />*/}
                  {/*<hr className="my-6" />*/}
                  {/*<h3>Billing Details</h3>
                <Form.Check type="checkbox" id="sameAsShipping" className="mb-4">
                  <Form.Check.Input type="checkbox" />
                  <Form.Check.Label className="fs-8 fw-normal">
                    Same as shipping address
                  </Form.Check.Label>
                </Form.Check>
                <EcomAddressTable data={shippingDetailsAddress} />
                  <hr className="my-6" />*/}
                {/*<DeliveryType />
                  <hr className="my-6" /> 
                <PaymentMethod /> */}

                {/*<div className="d-flex flex-column flex-sm-row gap-2 mb-7 mb-lg-0">
                  <Button variant="primary" type="submit" className="w-100">
                    Pay {currencyFormat(finalTotal)}
                  </Button>

                  <Button
                    variant="phoenix-secondary"
                    type="button"
                    className="text-nowrap"
                  >
                    Save Order and Exit
                  </Button>
                </div>*/}
                </Card>
              </form>
            </Col>
            <Col lg={5} xl={4}>
              <CheckoutSummaryCard total={currencyFormat(finalTotal, { minimumFractionDigits: 2 })} originalTotal={originalTotal} />
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={12}>
              <AddressSection />
            </Col>
          </Row>
        </Section>
          <Footer />
      </div></>
  );
};

export default Checkout;

/* @v0.1 init 
import Button from 'components/base/Button';
import Section from 'components/base/Section';
import { Col, Form, Row } from 'react-bootstrap';
import EcomAddressTable from 'components/tables/EcomAddressTable';
import { shippingDetailsAddress } from 'data/e-commerce';
import DeliveryType from 'components/modules/e-commerce/checkout/DeliveryType';
import { PaymentMethod } from 'components/modules/e-commerce/checkout/PaymentMethod';
import { currencyFormat } from 'helpers/utils';
import CheckoutSummaryCard from 'components/modules/e-commerce/checkout/CheckoutSummaryCard';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { defaultBreadcrumbItems } from 'data/commonData';

const Checkout = () => {
  return (
    <div className="pt-5 mb-9">
      <Section small className="py-0">
        <PageBreadcrumb items={defaultBreadcrumbItems} />
        <h2 className="mb-5">Check out</h2>
        <Row className="justify-content-between">
          <Col lg={7}>
            <form>
              <div className="d-flex align-items-end mb-4">
                <h3 className="mb-0 me-3">Shipping Details</h3>
                <Button variant="link" className="p-0" type="button">
                  Edit
                </Button>
              </div>
              <EcomAddressTable data={shippingDetailsAddress} />
              <hr className="my-6" />
              <h3>Billing Details</h3>
              <Form.Check type="checkbox" id="sameAsShipping" className="mb-4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Same as shipping address
                </Form.Check.Label>
              </Form.Check>
              <EcomAddressTable data={shippingDetailsAddress} />
              <hr className="my-6" />
              <DeliveryType />
              <hr className="my-6" />
              <PaymentMethod />

              <div className="d-flex flex-column flex-sm-row gap-2 mb-7 mb-lg-0">
                <Button variant="primary" type="submit" className="w-100">
                  Pay {currencyFormat(695.2)}
                </Button>

                <Button
                  variant="phoenix-secondary"
                  type="submit"
                  className="text-nowrap"
                >
                  Save Order and Exit
                </Button>
              </div>
            </form>
          </Col>
          <Col lg={5} xl={4}>
            <CheckoutSummaryCard />
          </Col>
        </Row>
      </Section>
    </div>
  );
};

export default Checkout; */
