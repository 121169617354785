import { useCart } from 'components/CartContext';
import { currencyFormat } from 'helpers/utils';
import React from 'react';

const OrderSummaryDetails = () => {
  const { totalPrice, discount } = useCart();
  return (
    <div>
      <div className="d-flex justify-content-between">
        <p className="text-body fw-semibold">Items subtotal :</p>
        <p className="text-body-emphasis fw-semibold">{currencyFormat(totalPrice, { minimumFractionDigits: 2})}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="text-body fw-semibold">Discount :</p>
        <p className="text-danger fw-semibold"> -{currencyFormat(discount, { minimumFractionDigits: 2})}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="text-body fw-semibold">Tax :</p>
        <p className="text-body-emphasis fw-semibold">
          {(19)}%
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="text-body fw-semibold">Subtotal :</p>
        <p className="text-body-emphasis fw-semibold">{currencyFormat((totalPrice - discount) * 1.19, { minimumFractionDigits: 2})}</p>
      </div>
    </div>
  );
};

export default OrderSummaryDetails;
