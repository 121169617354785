import axios from 'axios';
import { IMarketPaymentCart, IMarketPaymentProviderResponse } from 'data/models/payment/IPayment';
import { apiUrl, getHatateToken } from 'helpers/utils';

export const PaymentService = {
  createPaymentSession: async (cart: IMarketPaymentCart, partnerId?: string): Promise<IMarketPaymentProviderResponse> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };

    const payload = partnerId ? { ...cart, partnerId } : cart;

    try {
      const response = await axios.post<IMarketPaymentProviderResponse>(`${apiUrl}flow/create-checkout-session`, payload, { headers });

      return response.data;
    } catch (error: any) {
      console.error('Error procesando el pago:', error);
      throw error.response ? error.response.data.message : error.message;
    }
  },
};