import Section from 'components/base/Section';
import EcomCartSummaryCard from 'components/cards/EcomCartSummaryCard';
import DefaultCartNavbar from 'components/navbars/default-landing-navbar/DefaultCartNavbar';
import EcomCartTable from 'components/tables/EcomCartTable';
import { cartItems } from 'data/e-commerce/products';
import { Alert, Button, Card, Col, Row, Form, InputGroup } from 'react-bootstrap';
import bgbiz from 'assets/img/bg/bg-23.png';
import { faShoppingCart, faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useCart } from 'components/CartContext';

// Definir múltiples códigos promocionales con sus respectivos descuentos
const PROMO_CODES = {
  'linuxcorp2024': { discount: 4, description: 'Linux Corp Special' },
  'p0key2024': { discount: 5, description: 'Partner Special' },
  'anubis2024': { discount: 10, description: 'Anubis Premium' }
};

const MAX_CODE_LENGTH = 15;
const MIN_NUMBERS = 4;

interface PromoCodeFormProps {
  onApplyPromo: (applied: boolean) => void;
  isApplied: boolean;
  onUpdateDiscount: (amount: number, code: string, category: string) => void;
  currentCategory: string;
}

const PromoCodeForm: React.FC<PromoCodeFormProps> = ({ 
  onApplyPromo, 
  isApplied, 
  onUpdateDiscount, 
  currentCategory 
}) => {
  const [promoCode, setPromoCode] = useState('');
  const [promoError, setPromoError] = useState('');
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const { getAppliedPromo } = useCart();

  const validatePromoCode = (code: string) => {
    if (!code || code.length === 0) return false;
    const numberCount = (code.match(/\d/g) || []).length;
    return numberCount >= MIN_NUMBERS && /^[A-Z0-9]+$/.test(code);
  };

  const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    if (value.length <= MAX_CODE_LENGTH) {
      setPromoCode(value);
      setPromoError('');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validatePromoCode(promoCode)) {
      setPromoError('Code must contain at least 4 numbers and only letters');
      return;
    }

    const currentPromo = getAppliedPromo();
    
    if (currentPromo?.appliedTo === currentCategory) {
      setPromoError('A discount has already been applied to this product');
      return;
    }

    const normalizedCode = promoCode.toLowerCase();
    const promoInfo = Object.entries(PROMO_CODES).find(
      ([code]) => code.toLowerCase() === normalizedCode
    );

    if (promoInfo) {
      const [code, { discount, description }] = promoInfo;
      onApplyPromo(true);
      setPromoError('');
      setAppliedDiscount(discount);
      onUpdateDiscount(discount, code, currentCategory);
    } else {
      setPromoError('Invalid promo code');
      onApplyPromo(false);
      onUpdateDiscount(0, '', currentCategory);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="mb-4">
      <InputGroup>
        <InputGroup.Text>
          <FontAwesomeIcon icon={faTag} />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Enter promo code (4+ numbers & letters only)"
          value={promoCode}
          onChange={handlePromoCodeChange}
          disabled={isApplied}
          maxLength={MAX_CODE_LENGTH}
        />
        <Button 
          variant={isApplied ? "success" : "primary"}
          type="submit"
          disabled={isApplied || !promoCode}
        >
          {isApplied ? 'Applied' : 'Apply'}
        </Button>
      </InputGroup>
      {promoError && <div className="text-danger mt-2">{promoError}</div>}
      {isApplied && (
        <div className="text-success mt-2">
          Promo code applied! ${appliedDiscount} discount
        </div>
      )}
    </Form>
  );
};

const Cart = () => {
  const [promoApplied, setPromoApplied] = useState(false);
  const { cart, addToCart, discount, updateDiscount } = useCart();
  
  const firstCartItem = cart && cart.length > 0 ? cart[0] : null;
  const productCategory = firstCartItem?.category || '';

  return (
    <>
      <DefaultCartNavbar />
      <div className="bg-body-emphasis dark__bg-gray-1200" style={{
        backgroundImage: `url(${bgbiz})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        zIndex: 0,
        height: '100%',
        width: '100%',
        backgroundRepeat: 'no-repeat'
      }}>
        <Section small className="py-1">
          <h2 className="mb-6 text-gradient-info mt-2 mb-4">
            Cart <FontAwesomeIcon icon={faShoppingCart} size="lg" className="text-info text-xl" />
          </h2>
          <Row className="g-5">
            <Col xs={12} lg={8}>
              <EcomCartTable products={cartItems} />
            </Col>
            <Col xs={12} lg={4}>
              <Card className="mb-4">
                <Card.Body>
                  <PromoCodeForm 
                    onApplyPromo={setPromoApplied}
                    isApplied={promoApplied}
                    onUpdateDiscount={updateDiscount}
                    currentCategory={productCategory}
                  />
                </Card.Body>
              </Card>
              <EcomCartSummaryCard />
            </Col>
          </Row>
        </Section>
      </div>
    </>
  );
};

export default Cart;