/* @v1.0 - init conversación */
import React, { useEffect, useRef, useState } from 'react';
import Avatar from 'components/base/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { suggestions } from 'data/hazbot/chat';
import Button from 'components/base/Button';
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import Message from 'components/modules/chat/message';
import avatar from 'assets/img/Anubis/anubis.png';
import { BotService } from 'service/botService';
import { Card } from 'react-bootstrap';

type AvatarStatus = 'online' | 'offline' | 'do-not-disturb' | 'away';

const AnubisConversation = () => {
  const { conversation, sentMessage, selectedNeuroskill, selectedModule } = useChatWidgetContext();
  const messageEndRef = useRef<null | HTMLSpanElement>(null);
  const [botStatus, setBotStatus] = useState<AvatarStatus>('do-not-disturb');

  useEffect(() => {
    messageEndRef.current?.scrollIntoView();
  }, [conversation]);

  useEffect(() => {
    const fetchBotStatus = async () => {
      const status = await BotService.getStatus();
      setBotStatus(status.status === 'Available' ? 'online' : 'do-not-disturb');
    };

    fetchBotStatus();
    const intervalId = setInterval(fetchBotStatus, 30000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="text-center mt-auto">
        <Avatar
          src={avatar}
          size="3xl"
          status={botStatus}
          className="mx-auto"
        />
        <h5 className="mt-2 mb-3">{process.env.REACT_APP_NAME}</h5>
        <h6 className="text-center text-body-emphasis mb-0">
        {botStatus === 'online' 
        ? <>
        <span className="fw-bold">{selectedNeuroskill} NeuroSkill </span>
        with
        <span className="fw-bold"> {selectedModule} Module </span>
        <span className="text-success" style={{fontWeight: 'bold' }}>Activated</span>
        </>
        : "I'm currently offline. Please check back later."}
        </h6>

      </div>
      {!conversation.messages.length && (
        <div className="d-flex justify-content-center mt-5">
          <div className="row g-1" style={{ overflowX: 'hidden' }}>
            {suggestions.map((message, index) => (
              <div key={message} className="col-12 col-md-auto">
                <Card
                  onClick={() => sentMessage({ message })}
                  className={classNames(
                   'd-flex align-items-center text-body-emphasis bg-body-hover rounded-pill border border-primary py-1 ps-3 pe-3',
                   //'',
                    { 'mb-1': index !== suggestions.length - 1 }
                  )}
                  style={{ 
                    maxWidth: '280px',
                    display: 'block',
                    flexDirection: 'row',
                    alignItems: 'center',
                    overflow: 'hidden', // Prevent overflow
                  }}
                  >
                  <span className="mb-0 fw-semibold fs-9" style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 20, // Limit to 2 lines
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '1.5em',
                    flex: '1'
                  }}>
                   {message}
                  </span>
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    className="text-primary fs-9 ms-3"
                    style={{ flexShrink: 0 }} // Prevent icon from shrinking
                  />
                </Card>
              </div>
            ))}
          </div>
        </div>
      )}
      {conversation.messages.length > 0 && (
        <>
          <hr className="my-4 border-dashed border-top" />
          <div className="d-flex flex-column gap-2">
            {conversation.messages.map(message => (
              <Message
                message={message}
                user={conversation.user}
                key={message.id}
                showActions={false}
              />
            ))}
            <span ref={messageEndRef} />
          </div>
        </>
      )}
    </>
  );
};

export default AnubisConversation;