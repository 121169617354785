/* @v0.2 - add discount code */
import { createContext, useContext, ReactNode } from 'react';
import useLocalStorageState from 'use-local-storage-state'

export interface CartItem {
  category: string;
  price: number;
  quantity: number; 
}

interface PromoInfo {
  code: string;
  appliedTo: string; // category del producto
  discountAmount: number;
  appliedAt: number; // timestamp
}

interface CartContextType {
  cart: CartItem[];
  addToCart: (item: CartItem) => void;
  removeFromCart: (category: string) => void;
  updateCartQuantity: (category: string, quantity: number) => void;
  clearCart: () => void;
  totalItems: number;
  totalPrice: number;
  discount: number;
  updateDiscount: (amount: number, code: string, category: string) => void;
  clearDiscount: () => void;
  getAppliedPromo: () => PromoInfo | null;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const [cart, setCart] = useLocalStorageState<CartItem[]>('cart', {
    defaultValue: []
  });

  const [promoInfo, setPromoInfo] = useLocalStorageState<PromoInfo | null>('cart_promo', {
    defaultValue: null
  });

  const totalItems = cart.reduce((sum, item) => sum + item.quantity, 0);
  const totalPrice = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

  const addToCart = (item: CartItem) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((cartItem) => cartItem.category === item.category);
      if (existingItem) {
        return prevCart.map((cartItem) =>
          cartItem.category === item.category
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      } else {
        return [...prevCart, { ...item, quantity: 1 }];
      }
    });
  };

  const removeFromCart = (category: string) => {
    // Si el producto que se elimina tenía un descuento aplicado, eliminar el descuento
    if (promoInfo && promoInfo.appliedTo === category) {
      setPromoInfo(null);
    }
    
    setCart((prevCart) =>
      prevCart.filter((cartItem) => cartItem.category !== category)
    );
  };

  const updateCartQuantity = (category: string, quantity: number) => {
    setCart((prevCart) =>
      prevCart.map((cartItem) =>
        cartItem.category === category
          ? { ...cartItem, quantity }
          : cartItem
      )
      .filter((cartItem) => cartItem.quantity > 0)
    );

    // Si la cantidad llega a 0, eliminar el descuento si estaba aplicado a este producto
    if (quantity <= 0 && promoInfo && promoInfo.appliedTo === category) {
      setPromoInfo(null);
    }
  };

  const clearCart = () => {
    setCart([]);
    setPromoInfo(null); // Eliminar el descuento al limpiar el carrito
  };

  const updateDiscount = (amount: number, code: string, category: string) => {
    // Verificar si el producto existe en el carrito
    const productExists = cart.some(item => item.category === category);
    if (!productExists) {
      return;
    }

    setPromoInfo({
      code,
      appliedTo: category,
      discountAmount: amount,
      appliedAt: Date.now()
    });
  };

  const clearDiscount = () => {
    setPromoInfo(null);
  };

  const getAppliedPromo = () => promoInfo;

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        updateCartQuantity, 
        clearCart,
        totalItems,
        totalPrice,
        discount: promoInfo?.discountAmount || 0,
        updateDiscount,
        clearDiscount,
        getAppliedPromo
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

/* @v0.1 init 
import { createContext, useContext, ReactNode } from 'react';
import useLocalStorageState from 'use-local-storage-state'

export interface CartItem {
  category: string;
  price: number;
  quantity: number; 
}

interface CartContextType {
  cart: CartItem[];
  addToCart: (item: CartItem) => void;
  removeFromCart: (category: string) => void;
  updateCartQuantity: (category: string, quantity: number) => void;
  clearCart: () => void;
  totalItems: number;
  totalPrice: number;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const [cart, setCart] = useLocalStorageState<CartItem[]>('cart', {
    defaultValue: []
  });

  const totalItems = cart.reduce((sum, item) => sum + item.quantity, 0);
  const totalPrice = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

  const addToCart = (item: CartItem) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((cartItem) => cartItem.category === item.category);
      if (existingItem) {
        return prevCart.map((cartItem) =>
          cartItem.category === item.category
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      } else {
        return [...prevCart, { ...item, quantity: 1 }];
      }
    });
  };

  const removeFromCart = (category: string) => {
    setCart((prevCart) =>
      prevCart.filter((cartItem) => cartItem.category !== category)
    );
  };

  const updateCartQuantity = (category: string, quantity: number) => {
    setCart((prevCart) =>
      prevCart.map((cartItem) =>
        cartItem.category === category
          ? { ...cartItem, quantity }
          : cartItem
      )
      .filter((cartItem) => cartItem.quantity > 0)
    );
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        updateCartQuantity, 
        clearCart,
        totalItems,
        totalPrice,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
}; */