/* @v2.2 - discount promo applied 17122024_06:29 */
import Section from 'components/base/Section';
import EcomCartSummaryCard from 'components/cards/EcomCartSummaryCard';
import AddressSection from 'components/modules/landing/alternate/AddressSection';
import Footer from 'components/modules/landing/alternate/Footer';
import DefaultCartNavbar from 'components/navbars/default-landing-navbar/DefaultCartNavbar';
import EcomCartTable from 'components/tables/EcomCartTable';
import { cartItems } from 'data/e-commerce/products';
import { Alert, Button, Card, Col, Row, Form, InputGroup } from 'react-bootstrap';
import bgbiz from 'assets/img/bg/bg-23.png';
import { faShoppingCart, faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, useRef } from 'react';
import { useCart } from 'components/CartContext';
import { useUserContext } from 'layouts/UserContext';
import { IUser } from 'data/models/user/IUser';
import Pricing from 'components/modules/landing/alternate/pricing/Pricing';

interface IPartnerInfo {
  isPartner: boolean;
  completedOrders: any[];
}

interface IUserResponse extends IUser {
  partnerInfo: IPartnerInfo;
}

// Definir múltiples códigos promocionales con sus respectivos descuentos
const PROMO_CODES = {
  'linuxcorp2024': { discount: 4, description: 'Linux Corp Special' },
  'p0key2024': { discount: 5, description: 'Partner Special' },
  'anubis2024': { discount: 10, description: 'Anubis Premium' }
};

const MAX_CODE_LENGTH = 15;
const MIN_NUMBERS = 4;

const pricing = {
  category: 'Basic Anubis AI v0.1 - Release Anput',
  price: 11.0,
  initialStock: 1
};

interface PromoCodeFormProps {
  onApplyPromo: (applied: boolean) => void;
  isApplied: boolean;
  onUpdateDiscount: (amount: number, code: string, category: string) => void;
  currentCategory: string;
}

const PromoCodeForm: React.FC<PromoCodeFormProps> = ({ 
  onApplyPromo, 
  isApplied, 
  onUpdateDiscount, 
  currentCategory 
}) => {
  const [promoCode, setPromoCode] = useState('');
  const [promoError, setPromoError] = useState('');
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const { getAppliedPromo } = useCart();

  const validatePromoCode = (code: string) => {
    if (!code || code.length === 0) return false;
    const numberCount = (code.match(/\d/g) || []).length;
    return numberCount >= MIN_NUMBERS && /^[A-Z0-9]+$/.test(code);
  };

  const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    if (value.length <= MAX_CODE_LENGTH) {
      setPromoCode(value);
      setPromoError('');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validatePromoCode(promoCode)) {
      setPromoError('Code must contain at least 4 numbers and only letters');
      return;
    }

    const currentPromo = getAppliedPromo();
    
    if (currentPromo?.appliedTo === currentCategory) {
      setPromoError('A discount has already been applied to this product');
      return;
    }

    const normalizedCode = promoCode.toLowerCase();
    const promoInfo = Object.entries(PROMO_CODES).find(
      ([code]) => code.toLowerCase() === normalizedCode
    );

    if (promoInfo) {
      const [code, { discount, description }] = promoInfo;
      onApplyPromo(true);
      setPromoError('');
      setAppliedDiscount(discount);
      onUpdateDiscount(discount, code, currentCategory);
    } else {
      setPromoError('Invalid promo code');
      onApplyPromo(false);
      onUpdateDiscount(0, '', currentCategory);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="mb-4">
      <InputGroup>
        <InputGroup.Text>
          <FontAwesomeIcon icon={faTag} />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Enter promo code (4+ numbers & letters only)"
          value={promoCode}
          onChange={handlePromoCodeChange}
          disabled={isApplied}
          maxLength={MAX_CODE_LENGTH}
        />
        <Button 
          variant={isApplied ? "success" : "primary"}
          type="submit"
          disabled={isApplied || !promoCode}
        >
          {isApplied ? 'Applied' : 'Apply'}
        </Button>
      </InputGroup>
      {promoError && <div className="text-danger mt-2">{promoError}</div>}
      {isApplied && (
        <div className="text-success mt-2">
          Promo code applied! ${appliedDiscount} discount
        </div>
      )}
      {/*<div className="mt-3 small text-muted">
        Available promo codes:
        <ul className="mt-1">
          {Object.entries(PROMO_CODES).map(([code, { discount, description }]) => (
            <li key={code}>{code} - {description} (${discount} off)</li>
          ))}
        </ul>
      </div>*/}
    </Form>
  );
};

const Cart = () => {
  const [partnerId, setPartnerId] = useState('');
  const [partnerExists, setPartnerExists] = useState(false);
  const [isVerifying, setIsVerifying] = useState(true);
  const [promoApplied, setPromoApplied] = useState(false);
  const { cart, addToCart, discount, updateDiscount } = useCart();
  const { user, isLoading: isUserLoading } = useUserContext();
  const initRef = useRef(false);
  const [show, setShow] = useState(true);

  useEffect(() => {
    const verifyPartner = async () => {
      try {
        const path = window.location.pathname;
        const pathSegments = path.split('/');
        const id = pathSegments[pathSegments.length - 1];
        setPartnerId(id);

        const isValidObjectId = /^[0-9a-fA-F]{24}$/.test(id);
        
        if (!isValidObjectId) {
          setPartnerExists(false);
          return;
        }

        const response = await fetch(`https://backend.anubisai.net/api/user/${id}`);
        if (!response.ok) {
          throw new Error('User not found');
        }
        
        const partnerData: IUserResponse = await response.json();
        
        const exists = !!partnerData && 
                      !partnerData.deleted && 
                      partnerData.verified && 
                      partnerData.partnerInfo?.isPartner;

        setPartnerExists(exists);

        if (exists && !initRef.current) {
          const hasItem = cart.some(item => item.category === pricing.category);
          if (!hasItem) {
            addToCart({
              category: pricing.category,
              price: pricing.price,
              quantity: 1
            });
          }
          initRef.current = true;
        }
      } catch (error) {
        console.error('Error verifying partner:', error);
        setPartnerExists(false);
      } finally {
        setIsVerifying(false);
      }
    };

    if (!isUserLoading) {
      verifyPartner();
    }
  }, [isUserLoading, addToCart, cart, pricing.category, pricing.price]);

  if (isUserLoading || isVerifying) {
    return (
      <>
        <DefaultCartNavbar />
        <div className="text-center py-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <DefaultCartNavbar />
      <div 
        className="bg-body-emphasis dark__bg-gray-1200" 
        style={{
          backgroundImage: `url(${bgbiz})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          zIndex: 0,
          height: '100%',
          width: '100%',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Section small className="py-1">
          <h2 className="mb-6 text-gradient-info mt-2 mb-4">
            Cart&nbsp;
            <FontAwesomeIcon icon={faShoppingCart} size="lg" className="text-info text-xl" />
          </h2>
            {!partnerExists ? (
              <>
                <Alert variant="danger" className='text-white' onClose={() => setShow(false)} dismissible>
                <Alert.Heading>Oh snap! You got an error! :(</Alert.Heading>
                  <p>
                    The suggested link or business code is not authorized for distribution or the user is not a valid partner. We invite you to add products to your shopping cart.
                    <div>ID Code: {partnerId}</div>
                  </p>
                </Alert>
                <div className="alert alert-warning">
                  <div className="text-secondary">Never mind, we are here to help. Below we upload a list of our Artificial Intelligence products again.</div>
                </div>
                <Pricing />
              </>
            ) : (
              <>
                <Alert variant="success" className='text-white' onClose={() => setShow(false)} dismissible>
                <Alert.Heading>Congrats! You got a partner code! :D</Alert.Heading>
                  <p>
                    Congratulations, your promo code has been verified and validated in our systems. Once you complete the purchase transaction, you will be able to access my system.
                  </p>
                </Alert>
                <Row className="g-5">
                  <Col xs={12} lg={8}>
                    <EcomCartTable products={cartItems} />
                  </Col>
                  <Col xs={12} lg={4}>
                    <Card className="mb-4">
                      <Card.Body>
                        <PromoCodeForm 
                          onApplyPromo={setPromoApplied}
                          isApplied={promoApplied}
                          onUpdateDiscount={updateDiscount}
                          currentCategory={pricing.category}
                        />
                      </Card.Body>
                    </Card>
                    <EcomCartSummaryCard />
                  </Col>
                </Row>
              </>
            )}
              <AddressSection />
        </Section>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
/* @v2.1 - 17/12/2024_03:52 - _id & partner checker 
import Section from 'components/base/Section';
import EcomCartSummaryCard from 'components/cards/EcomCartSummaryCard';
import AddressSection from 'components/modules/landing/alternate/AddressSection';
import Footer from 'components/modules/landing/alternate/Footer';
import DefaultCartNavbar from 'components/navbars/default-landing-navbar/DefaultCartNavbar';
import EcomCartTable from 'components/tables/EcomCartTable';
import { cartItems } from 'data/e-commerce/products';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import bgbiz from 'assets/img/bg/bg-23.png';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, useRef } from 'react';
import { useCart } from 'components/CartContext';
import { useUserContext } from 'layouts/UserContext';
import { IUser } from 'data/models/user/IUser';
import Pricing from 'components/modules/landing/alternate/pricing/Pricing';

interface IPartnerInfo {
  isPartner: boolean;
  completedOrders: any[];
}

interface IUserResponse extends IUser {
  partnerInfo: IPartnerInfo;
}

const pricing = {
  category: 'Basic Anubis AI v0.1 - Release Anput',
  price: 11.0,
  initialStock: 1
};

const Cart = () => {
  const [partnerId, setPartnerId] = useState('');
  const [partnerExists, setPartnerExists] = useState(false);
  const [isVerifying, setIsVerifying] = useState(true);
  const { cart, addToCart } = useCart();
  const { user, isLoading: isUserLoading } = useUserContext();
  const initRef = useRef(false);
  const bluishColor = '#60d5ff';
  const [show, setShow] = useState(true);

  useEffect(() => {
    const verifyPartner = async () => {
      try {
        const path = window.location.pathname;
        const pathSegments = path.split('/');
        const id = pathSegments[pathSegments.length - 1];
        setPartnerId(id);

        const isValidObjectId = /^[0-9a-fA-F]{24}$/.test(id);
        
        if (!isValidObjectId) {
          setPartnerExists(false);
          return;
        }

        const response = await fetch(`https://backend.anubisai.net/api/user/${id}`);
        if (!response.ok) {
          throw new Error('Usuario no encontrado');
        }
        
        const partnerData: IUserResponse = await response.json();
        
        const exists = !!partnerData && 
                      !partnerData.deleted && 
                      partnerData.verified && 
                      partnerData.partnerInfo?.isPartner;

        setPartnerExists(exists);

        if (exists && !initRef.current) {
          const hasItem = cart.some(item => item.category === pricing.category);
          if (!hasItem) {
            addToCart({
              category: pricing.category,
              price: pricing.price,
              quantity: 1
            });
          }
          initRef.current = true;
        }
      } catch (error) {
        console.error('Error verificando partner:', error);
        setPartnerExists(false);
      } finally {
        setIsVerifying(false);
      }
    };

    if (!isUserLoading) {
      verifyPartner();
    }
  }, [isUserLoading, addToCart, cart, pricing.category, pricing.price]);

  if (isUserLoading || isVerifying) {
    return (
      <>
        <DefaultCartNavbar />
        <div className="text-center py-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <DefaultCartNavbar />
      <div 
        className="bg-body-emphasis dark__bg-gray-1200" 
        style={{
          backgroundImage: `url(${bgbiz})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          zIndex: 0,
          height: '100%',
          width: '100%',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Section small className="py-1">
          <h2 className="mb-6 text-gradient-info mt-2 mb-4">
            Cart&nbsp;
            <FontAwesomeIcon icon={faShoppingCart} size="lg" className="text-info text-xl" />
          </h2>
            {!partnerExists ? (
              <>
                <Alert variant="danger" className='text-white' onClose={() => setShow(false)} dismissible>
                <Alert.Heading>Oh snap! You got an error! :(</Alert.Heading>
                  <p>
                    The suggested link or business code is not authorized for distribution or the user is not a valid partner. We invite you to add products to your shopping cart.
                    <div>ID Code: {partnerId}</div>
                  </p>
                </Alert>
                <div className="alert alert-warning">
                  <div className="text-secondary">Never mind, we are here to help. Below we upload a list of our Artificial Intelligence products again.</div>
                </div>
                <Pricing />
              </>
            ) : (
              <>
                <Alert variant="success" className='text-white' onClose={() => setShow(false)} dismissible>
                <Alert.Heading>Congrats! You got a promo code! :D</Alert.Heading>
                  <p>
                    Congratulations, your promo code has been verified and validated in our systems. Once you complete the purchase transaction, you will be able to access my system.
                  </p>
                </Alert>
                <Row className="g-5">
                  <Col xs={12} lg={8}>
                    <EcomCartTable products={cartItems} />
                  </Col>
                  <Col xs={12} lg={4}>
                    <EcomCartSummaryCard />
                  </Col>
                </Row>
              </>
            )}
              <AddressSection />
        </Section>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
/* @v2.1 - 17/12/2024_03:52 
import Section from 'components/base/Section';
import EcomCartSummaryCard from 'components/cards/EcomCartSummaryCard';
import AddressSection from 'components/modules/landing/alternate/AddressSection';
import Footer from 'components/modules/landing/alternate/Footer';
import DefaultCartNavbar from 'components/navbars/default-landing-navbar/DefaultCartNavbar';
import EcomCartTable from 'components/tables/EcomCartTable';
import { cartItems } from 'data/e-commerce/products';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import bgbiz from 'assets/img/bg/bg-23.png';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, useRef } from 'react';
import { useCart } from 'components/CartContext';
import { useUserContext } from 'layouts/UserContext';
import { IUser } from 'data/models/user/IUser';
import Pricing from 'components/modules/landing/alternate/pricing/Pricing';

const pricing = {
  category: 'Basic Anubis AI v0.1 - Release Anput',
  price: 11.0,
  initialStock: 1
};

const Cart = () => {
  const [partnerId, setPartnerId] = useState('');
  const [partnerExists, setPartnerExists] = useState(false);
  const [isVerifying, setIsVerifying] = useState(true);
  const { cart, addToCart } = useCart();
  const { user, isLoading: isUserLoading } = useUserContext();
  const initRef = useRef(false);
  const bluishColor = '#60d5ff';
  const [show, setShow] = useState(true);

  useEffect(() => {
    const verifyPartner = async () => {
      try {
        const path = window.location.pathname;
        const pathSegments = path.split('/');
        const id = pathSegments[pathSegments.length - 1];
        setPartnerId(id);

        const isValidObjectId = /^[0-9a-fA-F]{24}$/.test(id);
        
        if (!isValidObjectId) {
          setPartnerExists(false);
          return;
        }

        const response = await fetch(`https://backend.anubisai.net/api/user/${id}`);
        if (!response.ok) {
          throw new Error('Usuario no encontrado');
        }
        
        const partnerData: IUser = await response.json();
        
        const exists = !!partnerData && !partnerData.deleted && partnerData.verified;
        setPartnerExists(exists);

        if (exists && !initRef.current) {
          const hasItem = cart.some(item => item.category === pricing.category);
          if (!hasItem) {
            addToCart({
              category: pricing.category,
              price: pricing.price,
              quantity: 1
            });
          }
          initRef.current = true;
        }
      } catch (error) {
        console.error('Error verificando partner:', error);
        setPartnerExists(false);
      } finally {
        setIsVerifying(false);
      }
    };

    if (!isUserLoading) {
      verifyPartner();
    }
  }, [isUserLoading, addToCart, cart, pricing.category, pricing.price]);

  if (isUserLoading || isVerifying) {
    return (
      <>
        <DefaultCartNavbar />
        <div className="text-center py-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <DefaultCartNavbar />
      <div 
        className="bg-body-emphasis dark__bg-gray-1200" 
        style={{
          backgroundImage: `url(${bgbiz})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          zIndex: 0,
          height: '100%',
          width: '100%',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Section small className="py-1">
          <h2 className="mb-6 text-gradient-info mt-2 mb-4">
            Cart&nbsp;
            <FontAwesomeIcon icon={faShoppingCart} size="lg" className="text-info text-xl" />
          </h2>
            {!partnerExists ? (
              <>
                <Alert variant="danger" className='text-white' onClose={() => setShow(false)} dismissible>
                <Alert.Heading>Oh snap! You got an error! :(</Alert.Heading>
                  <p>
                  The suggested link or business code is not authorized for distribution. We invite you to add products to your shopping cart.
                    <div>ID Code: {partnerId}</div>
                  </p>
                </Alert>
                <div className="alert alert-warning">
                  <div className="text-secondary">Never mind, we are here to help. Below we upload a list of our Artificial Intelligence products again.</div>
                </div>
                <Pricing />
              </>
            ) : (
              <>
                <Alert variant="success" className='text-white' onClose={() => setShow(false)} dismissible>
                <Alert.Heading>Congrats! You got a promo code! :D</Alert.Heading>
                  <p>
                  Congratulations, your promo code has been verified and validated in our systems. Once you complete the purchase transaction, you will be able to access my system.
                  </p>
                </Alert>
                <Row className="g-5">
                  <Col xs={12} lg={8}>
                    <EcomCartTable products={cartItems} />
                  </Col>
                  <Col xs={12} lg={4}>
                    <EcomCartSummaryCard />
                  </Col>
                </Row>
              </>
            )}
              <AddressSection />
        </Section>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
/* @v2.0 - 16/12/2024_12:54
import Section from 'components/base/Section';
import EcomCartSummaryCard from 'components/cards/EcomCartSummaryCard';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import AddressSection from 'components/modules/landing/alternate/AddressSection';
import Footer from 'components/modules/landing/alternate/Footer';
import DefaultCartNavbar from 'components/navbars/default-landing-navbar/DefaultCartNavbar';
import EcomCartTable from 'components/tables/EcomCartTable';
import { defaultBreadcrumbItems } from 'data/commonData';
import { cartItems } from 'data/e-commerce/products';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';
import { Card, Col, Row } from 'react-bootstrap';
import bgbiz from 'assets/img/bg/bg-23.png';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, useRef } from 'react';
import { useCart } from 'components/CartContext';

const pricing = {
  category: 'Basic Anubis AI v0.1 - Release Anput',
  price: 11.0,
  initialStock: 1
};

const Cart = () => {
  const [partnerUser, setPartnerUser] = useState('');
  const { cart, addToCart } = useCart();
  const initRef = useRef(false);

  useEffect(() => {
    const path = window.location.pathname;
    const pathSegments = path.split('/');
    const user = pathSegments[pathSegments.length - 1];
    setPartnerUser(user);

    const hasItem = cart.some(item => item.category === pricing.category);
    if (!hasItem && !initRef.current) {
      addToCart({
        category: pricing.category,
        price: pricing.price,
        quantity: 1
      });
      initRef.current = true;
    }
  }, []); 

  return (
    <>
      <DefaultCartNavbar />
      <div 
        className="bg-body-emphasis dark__bg-gray-1200" 
        style={{
          backgroundImage: `url(${bgbiz})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          zIndex: 0,
          height: '100%',
          width: '100%',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Section small className="py-1">
          <h2 className="mb-6 mt-1 text-gradient-info mt-2 mb-4">
            Cart sol: (link QR) {partnerUser} 
            <FontAwesomeIcon icon={faShoppingCart} size="lg" className="text-info text-xl" />
          </h2><div>Usuario existe: SI/NO</div>
          <Row className="g-5">
            <Col xs={12} lg={8}>
              <EcomCartTable products={cartItems} />
            </Col>
            <Col xs={12} lg={4}>
              <EcomCartSummaryCard />
            </Col>
          </Row>
        </Section>
        <AddressSection />
      </div>
      <Footer />
    </>
  );
};

export default Cart; */