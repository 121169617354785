import axios from 'axios';
import { IShellInput, IShellOutput } from 'data/models/shell/IShell';
import { apiUrl, getHatateToken } from 'helpers/utils';

export const ShellService = {
  sendCommand: async (shellInput: IShellInput): Promise<IShellOutput> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };

    try {
      const response = await axios.post<IShellOutput>(`${apiUrl}shell/input`, { command: shellInput.command }, { headers });

      return response.data;
    } catch (error: any) {
      console.error('Error procesando el pago:', error);
      throw error.response ? error.response.data.message : error.message;
    }
  },
};